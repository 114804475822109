import {
  BiBox,
  BiSolidUserRectangle,
  BiBuildings,
  BiSolidUserDetail,
  BiArchive,
  BiDollarCircle,
  BiSolidTruck
} from 'react-icons/bi';

import { Permission } from './permissions.types';

export const MASTER_DATA: Permission = {
  path: '/master-data',
  name: 'master-data',
  title: 'Master Data',
  permission: 'master data',
  isSidebarMenu: true,
  icon: BiBox
};

export const DRIVER_LIST: Permission = {
  path: '/master-data/driver',
  name: 'driver',
  title: 'Sopir',
  permission: 'view drivers',
  isSidebarMenu: true,
  icon: BiSolidUserRectangle
};

export const DRIVER_CREATE: Permission = {
  path: '/master-data/driver/create',
  name: 'driver',
  title: 'Sopir',
  permission: 'create drivers',
  isSidebarMenu: false
};

export const DRIVER_UPDATE: Permission = {
  path: '/master-data/driver/update/:uuid',
  name: 'driver',
  title: 'Sopir',
  permission: 'edit drivers',
  isSidebarMenu: false
};

export const VENDOR_LIST: Permission = {
  path: '/master-data/vendor',
  name: 'vendor',
  title: 'Vendor',
  permission: 'view vendors',
  isSidebarMenu: true,
  icon: BiBuildings
};

export const VENDOR_CREATE: Permission = {
  path: '/master-data/vendor/create',
  name: 'vendor',
  title: 'Vendor',
  permission: 'create vendors',
  isSidebarMenu: false
};

export const VENDOR_UPDATE: Permission = {
  path: '/master-data/vendor/update/:uuid',
  name: 'vendor',
  title: 'Vendor',
  permission: 'edit vendors',
  isSidebarMenu: false
};

export const CUSTOMER_LIST: Permission = {
  path: '/master-data/customer',
  name: 'customer',
  title: 'Customer',
  permission: 'view customers',
  isSidebarMenu: true,
  icon: BiSolidUserDetail
};

export const CUSTOMER_CREATE: Permission = {
  path: '/master-data/customer/create',
  name: 'customer',
  title: 'Customer',
  permission: 'create customers',
  isSidebarMenu: false
};

export const CUSTOMER_UPDATE: Permission = {
  path: '/master-data/customer/update/:uuid',
  name: 'customer',
  title: 'Customer',
  permission: 'edit customers',
  isSidebarMenu: false
};

export const GOODS_TYPE_LIST: Permission = {
  path: '/master-data/goods-type',
  name: 'goods-type',
  title: 'Jenis Barang',
  permission: 'view goods types',
  isSidebarMenu: true,
  icon: BiArchive
};

export const GOODS_TYPE_CREATE: Permission = {
  path: '/master-data/goods-type/create',
  name: 'goods-type',
  title: 'Jenis Barang',
  permission: 'create goods types',
  isSidebarMenu: false
};

export const GOODS_TYPE_UPDATE: Permission = {
  path: '/master-data/goods-type/update/:uuid',
  name: 'goods-type',
  title: 'Jenis Barang',
  permission: 'update goods types',
  isSidebarMenu: false
};

export const PRICE_LIST: Permission = {
  path: '/master-data/price',
  name: 'price',
  title: 'Harga',
  permission: 'view prices',
  isSidebarMenu: true,
  icon: BiDollarCircle
};

export const PRICE_LIST_CUSTOMER: Permission = {
  path: '/master-data/price/customer/:uuid',
  name: 'price',
  title: 'Harga',
  permission: 'view prices',
  isSidebarMenu: false
};

export const PRICE_CREATE: Permission = {
  path: '/master-data/price/create',
  name: 'price',
  title: 'Harga',
  permission: 'create prices',
  isSidebarMenu: false
};

export const PRICE_UPDATE: Permission = {
  path: '/master-data/price/update/:uuid',
  name: 'price',
  title: 'Harga',
  permission: 'edit prices',
  isSidebarMenu: false
};

export const VEHICLE_LIST: Permission = {
  path: '/master-data/vehicle',
  name: 'vehicle',
  title: 'Kendaraan',
  permission: 'view vehicles',
  isSidebarMenu: true,
  icon: BiSolidTruck
};

export const VEHICLE_CREATE: Permission = {
  path: '/master-data/vehicle/create',
  name: 'vehicle',
  title: 'Kendaraan',
  permission: 'create vehicles',
  isSidebarMenu: false
};


export const VEHICLE_UPDATE: Permission = {
  path: '/master-data/vehicle/update/:uuid',
  name: 'vehicle',
  title: 'Kendaraan',
  permission: 'update vehicles',
  isSidebarMenu: false
};

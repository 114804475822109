import { combineReducers } from 'redux';

import auth from './auth';
import user from './user';
import createInvoice from './createInvoice';

export default combineReducers({
  auth,
  user,
  createInvoice
});

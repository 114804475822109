import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: {
  token: string | undefined;
} = {
  token: undefined
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginAction: (
      state,
      action: PayloadAction<{ token: string | undefined }>
    ) => {
      state.token = action.payload.token;
    }
  }
});

export const { loginAction } = authSlice.actions;

export const authSelector = {
  token: (state: { auth: { token: string | undefined } }) => state.auth.token
};

export default authSlice.reducer;

import { lazy } from 'react';
import { Route, RouteMenu } from './routes.type';
import {
  CUSTOMER_CREATE,
  CUSTOMER_LIST,
  CUSTOMER_UPDATE,
  DRIVER_CREATE,
  DRIVER_LIST,
  DRIVER_UPDATE,
  MASTER_DATA,
  VENDOR_LIST,
  VENDOR_CREATE,
  VENDOR_UPDATE,
  GOODS_TYPE_LIST,
  GOODS_TYPE_CREATE,
  GOODS_TYPE_UPDATE,
  PRICE_LIST,
  PRICE_LIST_CUSTOMER,
  PRICE_CREATE,
  PRICE_UPDATE,
  VEHICLE_LIST,
  VEHICLE_CREATE,
  VEHICLE_UPDATE
} from '../constants/permissions/master-data';

const DriverListPage = lazy(
  () => import('../pages/MasterData/Driver/DriverListPage')
);
const CreateDriverPage = lazy(
  () => import('../pages/MasterData/Driver/CreateDriverPage')
);
const DriverUpdatePage = lazy(
  () => import('../pages/MasterData/Driver/DriverUpdatePage')
);
const VendorListPage = lazy(
  () => import('../pages/MasterData/Vendor/VendorListPage')
);
const VendorUpdatePage = lazy(
  () => import('../pages/MasterData/Vendor/VendorUpdatePage')
);
const CreateVendorPage = lazy(
  () => import('../pages/MasterData/Vendor/CreateVendorPage')
);
const CustomerListPage = lazy(
  () => import('../pages/MasterData/Customer/CustomerListPage')
);
const CreateCustomerPage = lazy(
  () => import('../pages/MasterData/Customer/CreateCustomerPage')
);
const CustomerUpdatePage = lazy(
  () => import('../pages/MasterData/Customer/CustomerUpdatePage')
);
const GoodsTypeListPage = lazy(
  () => import('../pages/MasterData/GoodsType/GoodsTypeListPage')
);
const GoodsTypeCreatePage = lazy(
  () => import('../pages/MasterData/GoodsType/GoodsTypeCreatePage')
);
const GoodsTypeUpdatePage = lazy(
  () => import('../pages/MasterData/GoodsType/GoodsTypeUpdatePage')
);
const PriceListPage = lazy(
  () => import('../pages/MasterData/Price/PriceListPage')
);
const PriceCustomerListPage = lazy(
  () => import('../pages/MasterData/Price/PriceCustomerListPage')
);
const PriceCreatePage = lazy(
  () => import('../pages/MasterData/Price/PriceCreatePage')
);
const PriceUpdatePage = lazy(
  () => import('../pages/MasterData/Price/PriceUpdatePage')
);
const VehicleListPage = lazy(
  () => import('../pages/MasterData/Vehicle/VehicleListPage')
);
const VehicleCreatePage = lazy(
  () => import('../pages/MasterData/Vehicle/VehicleCreatePage')
);
const VehicleUpdatePage = lazy(
  () => import('../pages/MasterData/Vehicle/VehicleUpdatePage')
);

const routes: Route[] = [
  {
    ...DRIVER_LIST,
    component: DriverListPage
  },
  {
    ...DRIVER_CREATE,
    component: CreateDriverPage
  },
  {
    ...DRIVER_UPDATE,
    component: DriverUpdatePage
  },
  {
    ...VENDOR_LIST,
    component: VendorListPage
  },
  {
    ...VENDOR_CREATE,
    component: CreateVendorPage
  },
  {
    ...VENDOR_UPDATE,
    component: VendorUpdatePage
  },
  {
    ...CUSTOMER_LIST,
    component: CustomerListPage
  },
  {
    ...CUSTOMER_CREATE,
    component: CreateCustomerPage
  },
  {
    ...CUSTOMER_UPDATE,
    component: CustomerUpdatePage
  },
  {
    ...GOODS_TYPE_LIST,
    component: GoodsTypeListPage
  },
  {
    ...GOODS_TYPE_CREATE,
    component: GoodsTypeCreatePage
  },
  {
    ...GOODS_TYPE_UPDATE,
    component: GoodsTypeUpdatePage
  },
  {
    ...PRICE_LIST,
    component: PriceListPage
  },
  {
    ...PRICE_LIST_CUSTOMER,
    component: PriceCustomerListPage
  },
  {
    ...PRICE_CREATE,
    component: PriceCreatePage
  },
  {
    ...PRICE_UPDATE,
    component: PriceUpdatePage
  },
  {
    ...VEHICLE_LIST,
    component: VehicleListPage
  },
  {
    ...VEHICLE_CREATE,
    component: VehicleCreatePage
  },
  {
    ...VEHICLE_UPDATE,
    component: VehicleUpdatePage
  }
];

const menus: RouteMenu[] = [
  {
    ...MASTER_DATA,
    subMenu: [
      CUSTOMER_LIST,
      DRIVER_LIST,
      VENDOR_LIST,
      GOODS_TYPE_LIST,
      PRICE_LIST,
      VEHICLE_LIST
    ]
  }
];

const masterData = {
  menus,
  routes
};

export default masterData;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type Role = {
  name?: string;
  permissions?: string[];
};

type User = {
  name?: string;
  username?: string;
  role?: Role;
};

const initialState: User = {};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserAction: (state, action: PayloadAction<User>) =>
      (state = action.payload)
  }
});

export const { setUserAction } = userSlice.actions;

export const userSelector = {
  user: (state: { user: User }) => state.user
};

export default userSlice.reducer;

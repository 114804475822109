import { BiPackage } from 'react-icons/bi';

import { Permission } from './permissions.types';

export const SHIPMENT_LIST: Permission = {
  path: '/shipment',
  name: 'shipment',
  title: 'Pengiriman',
  permission: 'create shipments',
  isSidebarMenu: true,
  icon: BiPackage
};

export const SHIPMENT_CREATE: Permission = {
  path: '/shipment/create',
  name: 'shipment',
  title: 'Shipment',
  permission: 'view shipments',
  isSidebarMenu: false
};

export const SHIPMENT_DELIVER: Permission = {
  path: '/shipment/deliver',
  name: 'shipment',
  title: 'Shipment',
  permission: 'deliver shipments',
  isSidebarMenu: false
};

export const SHIPMENT_DELIVERED: Permission = {
  path: '/shipment/delivered',
  name: 'shipment',
  title: 'Shipment',
  permission: 'deliver shipments',
  isSidebarMenu: false
};

export const SHIPMENT_DETAIL: Permission = {
  path: '/shipment/detail/:uuid',
  name: 'shipment',
  title: 'Shipment',
  permission: 'view shipments',
  isSidebarMenu: false
};

export const SHIPMENT_UPDATE: Permission = {
  path: '/shipment/update/:uuid',
  name: 'shipment',
  title: 'Shipment',
  permission: 'edit shipments',
  isSidebarMenu: false
};

export const SHIPMENT_ADDITIONAL_COST: Permission = {
  path: '/shipment/additional-cost/:uuid',
  name: 'shipment',
  title: 'Shipment',
  permission: 'edit shipments',
  isSidebarMenu: false
};

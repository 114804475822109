import { lazy } from 'react';
import { Route, RouteMenu } from './routes.type';
import { DASHBOARD_PAGE } from '../constants/permissions/dashboard';

const DashboardPage = lazy(() => import('../pages/Dashboard/DashboardPage'));

const routes: Route[] = [
  {
    ...DASHBOARD_PAGE,
    component: DashboardPage
  }
];

const menus: RouteMenu[] = [
  {
    ...DASHBOARD_PAGE
  }
];

const DASHBOARD = {
  menus,
  routes
};

export default DASHBOARD;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type CreateInvoice = {
  customer_uuid?: string;
};

const initialState: CreateInvoice = {};

const createInvoiceSlice = createSlice({
  name: 'createInvoice',
  initialState,
  reducers: {
    setCreateInvoiceCustomerUuid: (
      state,
      action: PayloadAction<CreateInvoice>
    ) => (state = action.payload)
  }
});

export const { setCreateInvoiceCustomerUuid } = createInvoiceSlice.actions;

export const createInvoiceSelector = {
  createInvoice: (state: { createInvoice: CreateInvoice }) =>
    state.createInvoice
};

export default createInvoiceSlice.reducer;

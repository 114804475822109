import { MdDashboard } from 'react-icons/md';

import { Permission } from './permissions.types';

export const DASHBOARD_PAGE: Permission = {
  path: '/dashboard',
  name: 'dashboard',
  title: 'Dashboard',
  permission: 'view shipments',
  isSidebarMenu: true,
  icon: MdDashboard
};
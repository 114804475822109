import { RiBillFill } from 'react-icons/ri';

import { Permission } from './permissions.types';

export const INVOICE_LIST: Permission = {
  path: '/invoice',
  name: 'invoice',
  title: 'Invoice',
  permission: 'create invoices',
  isSidebarMenu: true,
  icon: RiBillFill
};

export const INVOICE_DETAIL: Permission = {
  path: '/invoice/detail/:uuid',
  name: 'invoice',
  title: 'Invoice',
  permission: 'edit invoices',
  isSidebarMenu: false
};

export const INVOICE_CREATE: Permission = {
  path: '/invoice/create',
  name: 'invoice',
  title: 'Invoice',
  permission: 'create invoices',
  isSidebarMenu: false
};

import masterData from './master-data';
import shipment from './shipment';
import invoice from './invoice';
import dashboard from './dashboard';
import { Route, RouteMenu } from './routes.type';

export const routes: Route[] = [
  ...dashboard.routes,
  ...masterData.routes,
  ...shipment.routes,
  ...invoice.routes
];
export const menus: RouteMenu[] = [
  ...dashboard.routes,
  ...masterData.menus,
  ...shipment.menus,
  ...invoice.menus
];

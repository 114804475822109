import { lazy } from 'react';
import { Route, RouteMenu } from './routes.type';
import {
  SHIPMENT_CREATE,
  SHIPMENT_LIST,
  SHIPMENT_DELIVER,
  SHIPMENT_DETAIL,
  SHIPMENT_UPDATE,
  SHIPMENT_DELIVERED,
  SHIPMENT_ADDITIONAL_COST
} from '../constants/permissions/shipment';

const ShipmentListPage = lazy(
  () => import('../pages/Shipment/ShipmentListPage')
);
const ShipmentCreatePage = lazy(
  () => import('../pages/Shipment/ShipmentCreatePage')
);
const ShipmentDeliverPage = lazy(
  () => import('../pages/Shipment/ShipmentDeliverPage')
);
const ShipmentDeliveredPage = lazy(
  () => import('../pages/Shipment/ShipmentDeliveredPage')
);
const ShipmentDetailPage = lazy(
  () => import('../pages/Shipment/ShipmentDetailPage')
);
const ShipmentUpdatePage = lazy(
  () => import('../pages/Shipment/ShipmentUpdatePage')
);
const ShipmentAdditionalCost = lazy(
  () => import('../pages/Shipment/ShipmentAdditionalCost')
);

const routes: Route[] = [
  {
    ...SHIPMENT_LIST,
    component: ShipmentListPage
  },
  {
    ...SHIPMENT_CREATE,
    component: ShipmentCreatePage
  },
  {
    ...SHIPMENT_DELIVER,
    component: ShipmentDeliverPage
  },
  {
    ...SHIPMENT_DELIVERED,
    component: ShipmentDeliveredPage
  },
  {
    ...SHIPMENT_DETAIL,
    component: ShipmentDetailPage
  },
  {
    ...SHIPMENT_UPDATE,
    component: ShipmentUpdatePage
  },
  {
    ...SHIPMENT_ADDITIONAL_COST,
    component: ShipmentAdditionalCost
  }
];

const menus: RouteMenu[] = [
  {
    ...SHIPMENT_LIST
  }
];

const SHIPMENT = {
  menus,
  routes
};

export default SHIPMENT;

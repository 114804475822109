import { lazy } from 'react';
import { Route, RouteMenu } from './routes.type';
import {
  INVOICE_CREATE,
  INVOICE_LIST,
  INVOICE_DETAIL
} from '../constants/permissions/invoice';

const InvoiceListPage = lazy(() => import('../pages/Invoice/InvoiceListPage'));
const InvoiceCreatePage = lazy(
  () => import('../pages/Invoice/InvoiceCreatePage')
);
const InvoiceDetailPage = lazy(
  () => import('../pages/Invoice/InvoiceDetailPage')
);

const routes: Route[] = [
  {
    ...INVOICE_CREATE,
    component: InvoiceCreatePage
  },
  {
    ...INVOICE_LIST,
    component: InvoiceListPage
  },
  {
    ...INVOICE_DETAIL,
    component: InvoiceDetailPage
  }
];

const menus: RouteMenu[] = [
  {
    ...INVOICE_LIST
  }
];

const INVOICE = {
  menus,
  routes
};

export default INVOICE;

import { Suspense, lazy, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { routes } from './routes';
import DriverListPage from './pages/MasterData/Driver/DriverListPage';
import Loader from './components/Loader/Loader';
import { setAuthorizationHeader } from './configs/axios';
import { userSelector } from './store/slices/user';
import { hasPermission } from './helpers/permissions';

const LoginPage = lazy(() => import('./pages/Auth/LoginPage'));
const DefaultLayout = lazy(
  () => import('./layouts/DefaultLayout/DefaultLayout')
);

function App() {
  const user = useSelector(userSelector.user);

  const [permissions, setPermissions] = useState<string[]>([]);

  useEffect(() => {
    if (localStorage.getItem('token') && localStorage.getItem('token') != 'undefined') {
      const token = JSON.parse(localStorage.getItem('token') ?? '');
      setAuthorizationHeader(token);
    }
  }, []);

  useEffect(() => {
    setPermissions(user.role?.permissions ?? []);
  }, [user]);

  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route index path="/" element={<Navigate to={"/dashboard"} />} />
      <Route element={<DefaultLayout />}>
        {routes.map((route, index) => {
          const { path, component: Component, permission } = route;
          return Component ? (
            <Route
              key={index}
              path={path}
              element={
                <Suspense fallback={<Loader />}>
                  <Component />
                </Suspense>
              }
            />
          ) : null;
        })}
      </Route>
    </Routes>
  );
}

export default App;
